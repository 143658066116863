@font-face {
    font-family: 'Plantin';
    font-style: normal;
    font-weight: 100;
    src: data-uri('./src/fonts/plantin-light.woff') format('woff');
}

@font-face {
    font-family: 'Plantin';
    font-style: italic;
    font-weight: 100;
    src: data-uri('./src/fonts/plantin-light-italic.woff') format('woff');
}

@font-face {
    font-family: 'Plantin';
    font-style: normal;
    font-weight: 400;
    src: data-uri('./src/fonts/plantin.woff') format('woff');
}

@font-face {
    font-family: 'Plantin';
    font-style: normal;
    font-weight: 500;
    src: data-uri('./src/fonts/plantin-semibold.woff') format('woff');
}
