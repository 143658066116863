@import './fonts.less';

// variables

@COLOR_PRIMARY: #faf6f5;
@COLOR_SECONDARY: #1d1d1f;
@FONT_FAMILY_PRIMARY: 'Plantin', 'Georgia', 'serif';

// mixins

.inline() {
    display: inline;
    font-size: 1.00rem;
    font-weight: 100;
    line-height: 1.20rem;
    margin: 0;
    padding: 0;
}

// media

html{ font-size: 18px; }

@media screen and (min-width: 800px) {
    html{ font-size: 36px; }
}

// rules

html {
    background-color: @COLOR_SECONDARY;
    color: @COLOR_PRIMARY;
    font-family: @FONT_FAMILY_PRIMARY;
    font-weight: 100;
}
    body {
        margin: 2.25rem 1.50rem 1.50rem;
        padding: 0;
    }
        h1 {
            font-size: 2.25rem;
            font-weight: 100;
            line-height: 2.25rem;
            margin: 1.50rem 0;
            padding: 0;
        }
        h2 {
            font-size: 1.50rem;
            font-weight: 100;
            line-height: 1.50rem;
            margin: 1.50rem 0;
            padding: 0;
        }
        h5 {
            .inline();
            &:after {
                content: ' ';
            }
        }
        ul {
            .inline();
            list-style-type: none;

        }
        li {
            display: inline;
            &:before {
                content: ' — ';
            }
            li:before {
                content: ' | ';
            }
        }
        strong {
            font-weight: 500;
        }
        em {
            font-style: italic;
        }
        strong em {
            font-size: 0.70em;
            font-style: normal;
            font-weight: 400;
            text-transform: uppercase;
        }
        hr {
            border: none;
            border-top: solid 1px @COLOR_PRIMARY;
            display: block;
            margin: 1.50rem 0;
            opacity: 0.10;
            padding: 0;
        }
        a {
            color: @COLOR_PRIMARY;
            font-size: 0.70em;
            font-style: normal;
            font-weight: 400;
            outline: none;
            text-decoration: none;
            text-transform: uppercase;
            &:hover {
                text-decoration: underline;
            }
        }
